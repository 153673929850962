import React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"
import { motion } from "framer-motion"

import AppLayoutTemplate from "../frames/MainLayout"
import BlankHero from "../components/HeroBlank"
import SectionHeader from "../components/SectionHeader"
import { spacers, RGtheme } from "../components/utils/designsystem"
import MusicCard from "../components/MusicCard"
import ResponsiveSpacer from "../components/ResponsiveSpacer"
import ViewportLabel from "../components/ViewportLabel"

import { ApplyLayoutFrame as SnapToPageGrid } from "../frames/ResponsiveModuleContainer"

const ComponentFrame = styled.div`
  display: flex;
  flex-direction: column;

  grid-gap: ${spacers["56"].px};
  justify-content: flex-start;

  margin-bottom: ${spacers["56"].px};
`

const ProjectListFrame = styled(motion.div)`
  width: 100%;
  border-top: ${RGtheme.strokes['2'].solid};
  display: flex;
  flex-direction: column;
`

// const ProjectListItem = styled.div`
//   width: 100%;
// `;

const MusicPage = ({ data }) => {
  const musicData = [];
  data.dataJson.Music.sort((a, b) => a.year - b.year).forEach(item => {
    musicData.push({
      ...item,
    })
  })

  return (
    <AppLayoutTemplate>
      <BlankHero />

      <SnapToPageGrid>
        <ComponentFrame>
          <SectionHeader>Music Projects</SectionHeader>

          <ProjectListFrame>
            {musicData.reverse().map((item, index) => (
                <MusicCard
                  key={item.uniqueKey}
                  dataObj={item}
                  coverImgSharps={data.imgSharpsQuery}
                />
            ))}
          </ProjectListFrame>
          <ResponsiveSpacer showFill={false} />
        </ComponentFrame>
      </SnapToPageGrid>
      <ViewportLabel left>Music</ViewportLabel>
      <ViewportLabel right>Music</ViewportLabel>
    </AppLayoutTemplate>
  )
}

export const query = graphql`
  query MusicPageQuery {
    imgSharpsQuery: allFile(
      filter: { sourceInstanceName: { eq: "album-covers" } }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 1920, jpegQuality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          sourceInstanceName
        }
      }
    }
    dataJson {
      Music {
        group
        uniqueKey
        role
        year
        medium
        audioFileURL
        projLink
        thumbURL
        albumTitle
        recordLabel
        isVisible
      }
    }
  }
`

export default MusicPage
