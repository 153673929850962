import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import useWindowWidth from "../components/utils/useWindowWidth"

import styled from "styled-components"
import { RGtheme, device, spacers, color } from "./utils/designsystem"
import Img from "gatsby-image"
import ProjectLink from "../components/ProjectLink"

const TableRow = styled(motion.div)`
  display: grid;

  @media ${device.default} {
    grid-template-columns: 1fr;
    flex-direction: column;

    padding-bottom: 48px;
  }

  @media ${device.mobileS} {
    grid-template-columns: 1fr;
    flex-direction: column;

    border-bottom: ${RGtheme.strokes["2"].solid};
    padding-bottom: 48px;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
    flex-direction: row;

    padding-top: ${spacers["24"].px};
    padding-bottom: ${spacers["24"].px};

    border-bottom: ${RGtheme.strokes["2"].solid};
  }
`

const Cell = styled.div`
  font-size: ${RGtheme.typeStyles.dBody.fontsize};
  line-height: ${RGtheme.typeStyles.dBody.lineHeight};
`

const DataFrame = styled.div`
  display: grid;

  @media ${device.mobileS} {
    grid-template-columns: 1fr;
  }
  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
  }
  width: 100%;
`

const TextFrame = styled(Cell)`
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`

const ImgCell = styled(Cell)`
  @media ${device.default} {
    background-color: ${color["mid-grey"].hex};
    margin-top: 8px;
  }
  @media ${device.mobileS} {
    background-color: ${color["mid-grey"].hex};
    margin-top: 16px;
  }
  @media ${device.laptop} {
    background-color: ${color["bg-grey"].hex};
    margin-top: 0px;
  }
`

const MetaCell = styled(Cell)``

const RoleCell = styled(Cell)`
  font-style: italic;
  border-top: ${RGtheme.strokes["1"].dotted};
  padding-top: ${spacers["8"].px};

  width: 100%;
  @media ${device.laptop} {
    width: 100%;
  }

  align-self: flex-end;
`

const StyledImgWrapper = styled.div`
  padding-right: 0px;

  @media ${device.laptop} {
    padding-right: 0px;
    width: 100%;
  }
`

const Title = styled.a`
  font-style: italic;
`

const PlaceholderStyleDesktop = {
  backgroundColor: "#E1E1E1",
  marginRight: "24px",
}
const PlaceholderStyleMobile = {
  backgroundColor: "#eaeaea",
  margin: "24px",
  "max-width": "768px",
}

const ProjectName = styled.div``

const StyledProjectLink = styled(ProjectLink)`
  border: 1px solid red;
`

const TableRowVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      ease: "easeIn",
    },
  },
  hidden: {
    opacity: 0,
  },
}

function getCoverImage(thumbName, sharps) {
  let targetThumb = thumbName
  let thisImageObj = sharps.edges.filter(item => targetThumb === item.node.base)
  return thisImageObj[0].node.childImageSharp.fluid
}

function MusicCard(props) {
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })


 const size = useWindowWidth();

  const [currentWidth, setCurrentWidth] = useState(0)

  const isDesktop = size.width > 768

  if (currentWidth !== currentWidth) {
    setCurrentWidth(size.width)
  }

  return props.dataObj ? (
    <TableRow
      ref={ref}
      key={props.dataObj.uniqueKey}
      inital={"hidden"}
      animate={inView ? "visible" : "hidden"}
      variants={TableRowVariants}
    >
      <Cell>{props.dataObj.year}</Cell>

      <DataFrame>
        <ImgCell>
          {props.dataObj.thumbURL !== "" ? (
            <StyledImgWrapper>
              <Img
                fluid={getCoverImage(
                  props.dataObj.thumbURL,
                  props.coverImgSharps
                )}
                style={
                  isDesktop ? PlaceholderStyleDesktop : PlaceholderStyleMobile
                }
              ></Img>
            </StyledImgWrapper>
          ) : (
            <></>
          )}
        </ImgCell>
        <TextFrame>
          <ProjectName>
            {props.dataObj.group}
            <Title>, {props.dataObj.albumTitle}</Title>
          </ProjectName>

          <MetaCell>
            {props.dataObj.recordLabel}, {props.dataObj.medium}
          </MetaCell>
          <RoleCell>{props.dataObj.role}</RoleCell>

          {props.dataObj.projLink ? (
            <StyledProjectLink
              link={props.dataObj.projLink}
            ></StyledProjectLink>
          ) : (
            ""
          )}
        </TextFrame>
      </DataFrame>
    </TableRow>
  ) : (
    "loading..."
  )
}

export default MusicCard

MusicCard.propTypes = {
  dataObj: PropTypes.object.isRequired,
  coverImgSharps: PropTypes.object,
}
