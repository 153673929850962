import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

import LinkIcon from "../images/assets/external_link.svg"
import { RGtheme } from "./utils/designsystem"

function getMessage(mlink){
    if (mlink.includes('bandcamp') === true){
        return 'Listen On Bandcamp'
    } else if (mlink.includes('spotify') === true){
        return 'Listen On Spotify'
    } else {
        return mlink
    }
}

const LinkFrame = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  border-top: ${RGtheme.strokes["1"].dotted};
  padding-top: 16px;
`;

const StyledLink = styled.a`
  font-weight: 448;
`;

function ProjectLink(props) {

    return (
        <LinkFrame>
           <StyledLink href={props.link} target="_blank">{getMessage(props.link)}</StyledLink>
           <LinkIcon></LinkIcon>
        </LinkFrame>
    )
}

export default ProjectLink

ProjectLink.propTypes = {
    link: PropTypes.string.isRequired,
}




