import React, { Component } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import PropTypes from 'prop-types'

import { RGtheme, device } from "../components/utils/designsystem"

const AlignLeft = styled(motion.div)`
  display: none;

  transform: rotate(-90deg);
  position: fixed;
  top: 50%;

  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  @media ${device.laptop} {
    display: block;
  }
  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }
`

const AlignRight = styled(motion.div)`

  display: none;

  transform: rotate(90deg);
  position: fixed;
  top: 50%;
  right: 0px;

  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  @media ${device.laptop} {
    display: block;
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

`

const LeftVariants = {
  visible: {
    opacity: 1,
    "marginLeft": "0px",
    transition: {
      
      delayChildren: 0.4,
      ease: "backIn",
      duration: 1,
    },
  },
  hidden: {
    opacity: 0.8,
    "marginLeft": "-48px",
  },
}
const RightVariants = {
  visible: {
    opacity: 1,
    "marginRight": "0px",
    transition: {
     
      delayChildren: 0.4,
      ease: "backIn",
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    "marginRight": "-48px",
  },
  transition: {
    
    delayChildren: 0.4,
    ease: "backIn",
    duration: 1,
  },
}

export default class ViewportLabel extends Component {
  render() {
    return this.props.left ? (
      <AlignLeft initial={"hidden"} animate={"visible"} variants={LeftVariants}>
        {this.props.children}
      </AlignLeft>
    ) : (
      <AlignRight
        initial={"hidden"}
        animate={"visible"}
        variants={RightVariants}
      >
        {this.props.children}
      </AlignRight>
    )
  }
}

ViewportLabel.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  children: PropTypes.string.isRequired,
}
