import React from 'react'
import styled from 'styled-components';
import { spacers } from './utils/designsystem';

const StyledBlankHero = styled.div`
  height: ${spacers['296'].px};
`;

export default function BlankHero() {
    return (
        <StyledBlankHero>
        
        </StyledBlankHero>
    )
}